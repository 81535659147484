import React from "react";
import { style } from './style';

const Privacy = () => (
  <div style={style.explainer_section}>
  <div style={style.restaurants_wrapper}>
    <div style={style.restaurant_wrapper}>
      <div style={style.restaurant_title}>Privacy Policy</div>
      <div style={style.legal}>
        <div style={style.legal_text}>Last updated May 25, 2023</div>

        <div style={style.legal_text}>This privacy notice for the Bunch app ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</div>
        <div style={style.legal_text_list}>Download, view or use the Bunch up from the Apple App Store or the Google Play Store </div>
        <div style={style.legal_text_list}>Visit our website at https://www.letsbunch.com, or any website of ours that links to this privacy notice</div>
        <div style={style.legal_text_list}>Engage with us in other related ways, including any sales, marketing, or events</div>
        <div style={style.legal_text}>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at team@letsbunch.com.</div>
        <div></div>
        <div></div>
        <div style={style.legal_title}>SUMMARY OF KEY POINTS</div>
        <div></div>
        <div style={style.legal_text}>This summary provides key points from our privacy notice, but you can find out more details about any of these topics in the sections below.</div>
        <div></div>
        <div style={style.legal_text}>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Bunch and the Services, the choices you make, and the products and features you use.</div>
        <div></div>
        <div style={style.legal_text}>Do we process any sensitive personal information? We do not process sensitive personal information.</div>
        <div></div>
        <div style={style.legal_text}>Do we receive any information from third parties? We do not receive any information from third parties.</div>
        <div></div>
        <div style={style.legal_text}>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</div>
        <div></div>
        <div style={style.legal_text}>In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Read more below.</div>
        <div></div>
        <div style={style.legal_text}>How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Read more below.</div>
        <div></div>
        <div style={style.legal_text}>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Read more below.</div>
        <div></div>
        <div style={style.legal_text}>How do I exercise my rights? The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</div>
        <div></div>
        <div style={style.legal_text}>Want to learn more about what Bunch does with any information we collect? Read more below.</div>
        <div></div>
        <div></div>
        <div></div>
        <div style={style.legal_title}>1. WHAT INFORMATION DO WE COLLECT?</div>
        <div></div>
        <div style={style.legal_text_small_title}>Personal Information You Disclose to Us.</div>
        <div></div>
        <div style={style.legal_text}>In Short: We collect personal information that you provide to us.</div>
        <div></div>
        <div style={style.legal_text}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</div>
        <div></div>
        <div style={style.legal_text_small_title}>Personal Information Provided by You.</div>
        <div style={style.legal_text}>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</div>
        <div style={style.legal_text_list}>email addresses</div>
        <div style={style.legal_text_list}>passwords</div>
        <div style={style.legal_text_list}>your name</div>
        <div style={style.legal_text_list}>your occupation</div>
        <div style={style.legal_text_list}>your interests</div>

        <div style={style.legal_text_small_title}>Sensitive Information.</div>
        <div style={style.legal_text}>We do not process sensitive information.</div>
        <div style={style.legal_text_small_title}>Payment Data.</div>
        <div style={style.legal_text}>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/privacy.</div>
        <div></div>
        <div style={style.legal_text}>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</div>
        <div></div>
        <div style={style.legal_text_small_title}>Information Automatically Collected.</div>
        <div></div>
        <div style={style.legal_text}>In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</div>
        <div></div>
        <div style={style.legal_text}>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</div>
        <div></div>
        <div style={style.legal_text}>Like many businesses, we also collect information through cookies and similar technologies.</div>
        <div></div>
        <div style={style.legal_text}>The information we collect includes:</div>
        <div style={style.legal_text_list}>Log and Usage Data. </div>
        <div style={style.legal_text_list}>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</div>
        <div style={style.legal_text_list}>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</div>
        <div style={style.legal_text_list}>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). </div>
        <div style={style.legal_text}>You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</div>
        <div style={style.legal_title}>2. HOW DO WE PROCESS YOUR INFORMATION?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</div>
        <div></div>
        <div style={style.legal_text}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</div>
        <div style={style.legal_text_list}>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</div>
        <div style={style.legal_text_list}>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</div>
        <div style={style.legal_text_list}>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</div>
        <div style={style.legal_text_list}>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</div>
        <div style={style.legal_text_list}>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</div>
        <div></div>
        <div style={style.legal_title}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>If you are located in the EU or UK, this section applies to you.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</div>
        <div style={style.legal_text}>Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Read more below.</div>
        <div style={style.legal_text}>Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</div>
        <div style={style.legal_text_list}>Analyze how our services are used so we can improve them to engage and retain users</div>
        <div style={style.legal_text_list}>Diagnose problems and/or prevent fraudulent activities</div>
        <div style={style.legal_text_list}>Understand how our users use our products and services so we can improve user experience</div>
        <div style={style.legal_text}>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</div>
        <div style={style.legal_text}>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>If you are located in Canada, this section applies to you.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time. Read more below.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</div>
        <div style={style.legal_text_list}>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</div>
        <div style={style.legal_text_list}>For investigations and fraud detection and prevention</div>
        <div style={style.legal_text_list}>For business transactions provided certain conditions are met</div>
        <div style={style.legal_text_list}>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</div>
        <div style={style.legal_text_list}>For identifying injured, ill, or deceased persons and communicating with next of kin</div>
        <div style={style.legal_text_list}>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</div>
        <div style={style.legal_text_list}>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</div>
        <div style={style.legal_text_list}>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</div>
        <div style={style.legal_text_list}>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</div>
        <div style={style.legal_text_list}>If the collection is solely for journalistic, artistic, or literary purposes</div>
        <div style={style.legal_text_list}>If the information is publicly available and is specified by the regulations</div>
        <div></div>
        <div style={style.legal_title}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We might need to share the contact information that you have provided us with (like your email address) with Business Partners or in case of a Business Transfer.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may need to share the contact information that you've shared with us in the following situations:</div>
        <div style={style.legal_text_list}>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</div>
        <div style={style.legal_text_list}>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.</div>
        <div></div>
        <div style={style.legal_title}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We may use cookies and other tracking technologies to collect and store your information.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</div>
        <div></div>
        <div style={style.legal_title}>6. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than twenty four (24) months past the termination of the user's account.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</div>
        <div></div>
        <div style={style.legal_title}>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</div>
        <div></div>
        <div style={style.legal_title}>8. DO WE COLLECT INFORMATION FROM MINORS?</div>
        <div></div>
        <div style={style.legal_text}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at team@letsbunch.com.</div>
        <div></div>
        <div style={style.legal_title}>9. WHAT ARE YOUR PRIVACY RIGHTS?</div>
        <div></div>
        <div style={style.legal_text}>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We will consider and act upon any request in accordance with applicable data protection laws.</div>
        <div style={style.legal_text}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>If you are located in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Account Information</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>If you would at any time like to review or change the information in your account or terminate your account, you can:</div>
        <div style={style.legal_text}>Log in to your account settings and update your user account. To delete your user account, contact us at team@letsbunch.com</div>
        <div style={style.legal_text}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>If you have questions or comments about your privacy rights, you may email us at team@letsbunch.com.</div>
        <div></div>
        <div style={style.legal_title}>10. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
        <div></div>
        <div style={style.legal_text}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</div>
        <div></div>
        <div style={style.legal_title}>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
        <div></div>
        <div style={style.legal_text}>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_title}>CCPA Privacy Notice</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>The California Code of Regulations defines a "resident" as:</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</div>
        <div style={style.legal_text}>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</div>

        <div style={style.legal_text}>All other individuals are defined as "non-residents."</div>

        <div style={style.legal_text}>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>What categories of personal information do we collect?</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We have collected the following categories of personal information in the past twelve (12) months: A, B, D, F, G, I, J</div>
        <div style={style.legal_text}>Category</div>
        <div style={style.legal_text_list}>A. Identifiers</div>
        <div style={style.legal_text_list}>B. Personal information categories listed in the California Customer Records statute</div>
        <div style={style.legal_text_list}>C. Protected classification characteristics under California or federal law</div>
        <div style={style.legal_text_list}>D. Commercial information</div>
        <div style={style.legal_text_list}>E. Biometric information</div>
        <div style={style.legal_text_list}>F. Internet or other similar network activity</div>
        <div style={style.legal_text_list}>G. Geolocation data</div>
        <div style={style.legal_text_list}>H. Audio, electronic, visual, thermal, olfactory, or similar information</div>
        <div style={style.legal_text_list}>I. Professional or employment-related information</div>
        <div style={style.legal_text_list}>J. Education Information</div>
        <div style={style.legal_text_list}>K. Inferences drawn from other personal information</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may also collect other personal information outside of these categories instances where you interact with us in person, online, or by phone or mail in the context of:</div>
        <div style={style.legal_text_list}>Receiving help through our customer support channels;</div>
        <div style={style.legal_text_list}>Participation in customer surveys or contests; and</div>
        <div style={style.legal_text_list}>Facilitation in the delivery of our Services and to respond to your inquiries.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>How do we use and share your personal information?</div>
 
        <div style={style.legal_text}>More information about our data collection and sharing practices can be found in this privacy notice.</div>

        <div style={style.legal_text}>You may contact us by email at team@letsbunch.com, or by referring to the contact details at the bottom of this document.</div>

        <div style={style.legal_text}>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Will your information be shared with anyone else?</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Bunch has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Bunch will not sell personal information in the future belonging to website visitors, users, and other consumers.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Your rights with respect to your personal data</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Right to request deletion of the data — Request to delete</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Right to be informed — Request to know</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Depending on the circumstances, you have a right to know:</div>
        <div style={style.legal_text_list}>whether we collect and use your personal information;</div>
        <div style={style.legal_text_list}>the categories of personal information that we collect;</div>
        <div style={style.legal_text_list}>the purposes for which the collected personal information is used;</div>
        <div style={style.legal_text_list}>whether we sell your personal information to third parties;</div>
        <div style={style.legal_text_list}>the categories of personal information that we sold or disclosed for a business purpose;</div>
        <div style={style.legal_text_list}>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</div>
        <div style={style.legal_text_list}>the business or commercial purpose for collecting or selling personal information.</div>
        <div style={style.legal_text}>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We will not discriminate against you if you exercise your privacy rights.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Verification process</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text}>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</div>
        <div style={style.legal_text}></div>
        <div style={style.legal_text_small_title}>Other privacy rights</div>
        <div style={style.legal_text}>You may object to the processing of your personal information.</div>
        <div style={style.legal_text}>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</div>
        <div style={style.legal_text}>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</div>
        <div style={style.legal_text}>You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</div>
        <div style={style.legal_text}>To exercise these rights, you can contact us by email at team@letsbunch.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</div>
        <div></div>

        <div style={style.legal_title}>13. Child Safety and Privacy</div>
        <div></div><br/>
        <div style={style.legal_text}>
          We are committed to protecting the privacy and safety of children who use our services. Our platform is not intended for use by individuals under the age of 21, and we do not knowingly collect, use, or disclose personal information from children without verifiable parental consent.
        </div>
        <div></div><br/>
        <div style={style.legal_text}>
          If we become aware that we have collected personal information from a child without the necessary consent, we will take immediate steps to delete such information from our records.
        </div>
        <div></div><br/>
        <div style={style.legal_text}>
          Parents and guardians are encouraged to monitor their children's online activities and help enforce safe and responsible use of our services. If you believe your child has provided us with personal information without your consent, please contact us at team@letsbunch.com so that we can take the necessary actions.
        </div>
        <div></div><br/>
        <div style={style.legal_text}>
        Our platform also includes measures to prevent inappropriate content and interactions, including but not limited to:

        </div>
        <div></div><br/>
        <div style={style.legal_text}>
        1. Content moderation to block inappropriate or harmful content.<br/>
        2. Reporting tools to notify us of content or behavior that violates our child safety policies.<br/>
        3. Restrictions on messaging and interaction features for users identified as minors.<br/>
        <div></div><br/>
        We strive to create a safe and positive experience for all users, especially children, while maintaining compliance with applicable laws, including the Children's Online Privacy Protection Act (COPPA) and other relevant regulations.
          </div>
          <div></div>


        <div style={style.legal_title}>14. DO WE MAKE UPDATES TO THIS NOTICE?</div>
        <div></div>
        <div style={style.legal_text}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</div>
        <div></div>
        <div style={style.legal_text}>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</div>
        <div></div>
        <div style={style.legal_title}>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
        <div></div>
        <div style={style.legal_text}>If you have questions or comments about this notice, you may email us at team@letsbunch.com.</div>
        <div></div>
        <div style={style.legal_title}>16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</div>
        <div></div>
        <div style={style.legal_text}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email us at team@letsbunch.com.</div>
      </div>
    </div>
    </div>
  </div>
);

export default Privacy;
