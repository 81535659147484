import React from "react";

const Footer = () => {

  let year = (new Date()).getFullYear();

  return <footer style={{padding: 40}}>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <p>&copy; {year} Focal Point Technologies</p>
      <div>
        <a style={{fontSize: 20, textTransform: 'uppercase', color: 'black', textDecorationLine: 'underline'}} href="mailto:team@letsbunch.com">Email Us</a>
      </div>
    </div>
    
  </footer>
};

export default Footer;
