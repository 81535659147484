import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => (
  <nav style={{background: 'transparent'}}>
   <a href="/" style={{display: 'flex', flexDirection: 'row', width: 200, padding: 20,  textDecorationLine: 'none'}}>
    <img style={{width: 50, height: 50}} src={'https://diningsocial-assets.s3.amazonaws.com/icon.png'} />
    <div style={{fontFamily: '"Plus Jakarta Sans", serif', fontSize: 28, alignContent: 'center', marginLeft: 10, marginTop: 0, color: 'black', textDecorationLine: 'none', fontWeight: '600'}}>Bunch</div>
   </a>
    {/* <ul>https://diningsocial-assets.s3.amazonaws.com/Apple_Badge.svg
      <li><Link to="/about">About</Link></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul> */}
  </nav>
);

export default Navbar;
