import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ProfileConnect from "./pages/ProfileConnect";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ChangePassword from "./pages/ChangePassword";

const App = () => (
  <Router>
    <div>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mobile_app/" element={<Home />} />
          <Route path="/mobile_app/profile" element={<ProfileConnect />} />
          <Route path="/change_password/:slug" element={<ChangePassword />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;
