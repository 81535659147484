const colors = {
  SOME_BORDER: '#bbbbc0',
  PRIMARY: '#fdcd37',
  PRIMARY_TRANSLUCENT: '#E6EBFF',
  PRIMARY_FADED: '#c2dff8',
  SECONDARY: '#00BFFF',
  SECONDARY_LIGHT: '#cbedf8',
  SECONDARY_MEDIUM: '#ace3f5',
  PRIMARY_RED: '#e32652',
  BACKGROUND_GRAY_SHADE: '#c1dfea',
  DARK_GREY: '#404040',
  TEXT_GREY: '#575762',
  CONSULT_TITLE_GREY: '#4b4b4b',
  BORDER_GREY: '#e7e7e7',
  BACKGROUND_GREY: '#F2F3F6',
  GREY: 'grey',
  GREEN: '#53d2dd',
  BLUE: '#2652e3',
  DARK_GREEN: '#29929b',
  LIGHT_GREEN: '#94e2e9',
  RED: '#FF495C',
  YELLOW: '#d3fd00',
  LIGHT_BLUE_BACKGROUND: '#f7f8fc',
  BLACK: '#1c1c1c',
  ORANGE: '#f5ac2e',
  LIGHT_ORANGE: '#f0dfa7',
  BACKGROUND_BLACK: '#0e0e0e',
  BACKGROUND_GREY_BLUE: '#e5e9f5',
  HILIGHT_RED: '#f74d85',
  LIGHT_RED: '#f492b2',
  HILIGHT_YELLOW: '#6ff370',
  HILIGHT_ORANGE: '#f3d46f',
  WHITE: 'white',
  "vibrantGreen": "#00FF7F",
  "limeGreen": "#32CD32",
  "brightOrange": "#FFA500",
  "tomatoOrange": "#FF6347",
  "skyBlue": "#87CEEB",
  "dodgerBlue": "#00BFFF",
  "sunshineYellow": "#FFD700",
  "brightYellow": "#fdcd37",
  "brightYellowOld": "#FFEB3B",
  "coral": "#FF7F50",
  "peach": "#FFDAB9",
  "warmPink": "#FF69B4",
  "lightPink": "#FFC0CB",
  "brightPurple": "#9370DB",
  "blueViolet": "#8A2BE2",
  "blueVioletLight": "#d5c2e7",
  "deepOrange": "#FF4500",
  "teal": "#008080",
  "vibrantOrange": '#FF4500',
  "electricBlue": '#007BFF',
  "richCoral": '#FF5733',
  "limeGreen": '#C7EA46',
  "vibrantTurquoise": '#40E0D0',
  "sunshineYellow": '#FFD700',
}

//2E58FF

export default colors
